*::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  background: #404040 !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #737373 !important;
}

*::-webkit-scrollbar {
  background: #A0A0A0;
  width:8px;
}
